import { EhrRoles } from "@core/constants";
import { useSession } from "@core/hooks/useSession";
import { api, APIErrorTypeV3, useUser } from "@core/services/nocd-api";
import useClinicianSuccessionFlag from "@core/services/nocd-api/clinicians/queries/useClinicianSuccessionFlag";
import useMembersNotAssignedToRecommendedProtocol from "@core/services/nocd-api/clinicians/queries/useMembersNotAssignedToRecommendedProtocol";
import AccessDenied from "@core/ui/AccessDenied";
import { hasPermission } from "@core/utils/access-control";
import { useCaseloadReviewNotifications } from "@features/caseload-review/hooks/useCaseloadReviewNotifications";
import {
  ChangeCircle,
  ChangeCircle as ChangeCircleIcon,
  Chat as ChatIcon,
  CheckCircle,
  ChevronLeft as ChevronLeftIcon,
  Dashboard as DashboardIcon,
  Event as EventIcon,
  FolderOutlined,
  ForkRightOutlined,
  ForumOutlined as ForumIcon,
  Group as GroupIcon,
  HeadsetMic as HeadsetMicIcon,
  Home as HomeIcon,
  ListAlt as ListAltIcon,
  Menu as MenuIcon,
  MonitorHeart as MonitorHeartIcon,
  PersonSearch as PersonSearchIcon,
  Repeat as RepeatIcon,
  Reviews as ReviewsIcon,
  SportsMartialArtsOutlined as SportsMartialArtsOutlinedIcon,
  Star as StarIcon,
  Stars as StarsIcon,
  SupervisorAccount as SupervisorAccountIcon,
  ViewList as ViewListIcon,
  Visibility as VisibilityIcon,
  WatchLater as WatchLaterIcon,
  WorkHistoryOutlined,
} from "@mui/icons-material";
import {
  AppBar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Toolbar,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useFlags } from "launchdarkly-react-client-sdk";
import { intersection, isEmpty } from "lodash";
import Image from "next/image";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import ProBadge from "public/images/pro-badge.svg";
import { useCallback, useState } from "react";

import NotAuthenticated from "./NotAuthenticated";
import SideEffects from "./SideEffects";
import UserMenu from "./UserMenu";

const useNavigationDrawer = () => {
  const [isNavigationDrawerOpen, setIsNavigationDrawerOpen] = useState(false);

  const openNavigationDrawer = useCallback(
    () => setIsNavigationDrawerOpen(true),
    []
  );
  const closeNavigationDrawer = useCallback(
    () => setIsNavigationDrawerOpen(false),
    []
  );

  return {
    openNavigationDrawer,
    closeNavigationDrawer,
    isNavigationDrawerOpen,
  };
};

const DRAWER_WIDTH = 300;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    zIndex: theme.zIndex.drawer + 3,
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // Necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 2,
    maxHeight: 65,
  },
  hidden: {
    display: `none`,
    minHeight: 0,
  },
  contentWrapper: {
    flexGrow: 1,
    position: "relative",
    padding: theme.spacing(4),
  },
}));

interface AppShellProps {
  children: React.ReactNode;
  title: React.ReactNode;
  acceptedRoles: string[];
  backgroundColor?: "gray" | "white";
  hideShell?: boolean;
}

interface SchedulingComplianceListItemProps {
  closeNavigationDrawer: () => void;
  isNavigationDrawerOpen: boolean;
  clinicianEmail: string;
}

const SchedulingComplianceListItem = ({
  closeNavigationDrawer,
  clinicianEmail,
  isNavigationDrawerOpen,
}: SchedulingComplianceListItemProps) => {
  const router = useRouter();
  const { data } = useMembersNotAssignedToRecommendedProtocol(clinicianEmail, {
    enabled: !isNavigationDrawerOpen ? false : undefined,
  });

  return (
    <ListItem
      button
      onClick={() => {
        closeNavigationDrawer();
        return router.push("/scheduling/compliance");
      }}
    >
      <ListItemIcon>
        <Badge badgeContent={data?.missing_count ?? 0} color="error">
          <ForkRightOutlined />
        </Badge>
      </ListItemIcon>
      <ListItemText primary="Protocol Deviations" />
    </ListItem>
  );
};

const CaseloadReviewListItem = ({
  closeNavigationDrawer,
}: {
  closeNavigationDrawer: () => void;
}) => {
  const router = useRouter();
  const { data } = useCaseloadReviewNotifications();

  return (
    <ListItem
      button
      onClick={() => {
        closeNavigationDrawer();
        return router.push("/caseload-review");
      }}
    >
      <ListItemIcon>
        <Badge badgeContent={data?.notifications ?? 0} color="error">
          <Box sx={{ position: "relative" }}>
            <FolderOutlined sx={{ width: 24, height: 24 }} />

            <CheckCircle
              sx={{
                position: "absolute",
                top: "12px",
                left: "12px",
                background: "white",
                width: 14,
                height: 14,
              }}
            />
          </Box>
        </Badge>
      </ListItemIcon>
      <ListItemText primary="Caseload Review" />
    </ListItem>
  );
};

export default function AppShell({
  children,
  title,
  acceptedRoles,
  backgroundColor = "gray",
  hideShell = false,
}: AppShellProps): JSX.Element {
  const classes = useStyles();

  const { data: session, isLoading: isAuthenticating } = useSession();
  const { accessToken: localAccessToken } = session ?? {};

  const { data: user, error: userError } = useUser();
  const isUserAuthenticated =
    !!localAccessToken &&
    userError?.name !== APIErrorTypeV3.INVALID_GOOGLE_AUTH;

  const { data: successionFlag } = useClinicianSuccessionFlag(user?.email);

  const {
    associateProgramEnabled,
    enableSessionhealth,
    enableMyEvaluations,
    enableCaseloadReview,
    enableComOverviewDashboard,
  } = useFlags();

  const router = useRouter();
  const { push } = router;

  const isClinician = Boolean(user?.roles?.includes(EhrRoles.CLINICIAN));
  const isClinicianPro = Boolean(user?.roles?.includes(EhrRoles.CLINICIAN_PRO));

  const isMemberAdvocate = Boolean(
    user?.roles?.includes(EhrRoles.MEMBER_ADVOCATE)
  );
  const isMemberAdvocateAuditor = Boolean(
    user?.roles?.includes(EhrRoles.MEMBER_ADVOCATE_AUDITOR)
  );
  const isScheduler = Boolean(user?.roles?.includes(EhrRoles.SCHEDULING));
  const isClinicianScheduleApprover = Boolean(
    user?.roles?.includes(EhrRoles.CLINICIAN_SCHEDULE_APPROVER)
  );

  const isClinicianSupervisor = user?.roles?.includes(
    EhrRoles.CLINICIAN_SUPERVISOR
  );
  const isClinicalLeadSpecialtyConditions = user?.roles?.includes(
    EhrRoles.CLINICAL_LEAD_SPECIALTY_CONDITIONS
  );
  const isFormBaselineChangeRequestAdmin = user?.roles?.includes(
    EhrRoles.FORM_BASELINE_CHANGE_REQUEST_ADMIN
  );
  const isAssessmentArchiveChangeRequestAdmin = user?.roles?.includes(
    EhrRoles.ASSESSMENT_ARCHIVE_CHANGE_REQUEST_ADMIN
  );
  const isClinicalLeadership = user?.roles?.includes(
    EhrRoles.CLINICAL_LEADERSHIP
  );
  const isDiagnosticApprover = user?.roles?.includes(
    EhrRoles.DIAGNOSTIC_APPROVER
  );
  const canManageClinicianEvaluations =
    user?.roles?.includes(EhrRoles.CLINICAL_LEADERSHIP) ||
    user?.roles?.includes(EhrRoles.THERAPIST_EXPERIENCE_TEAM);

  const canReviewDischargeRequests = hasPermission(
    user?.roles,
    "DischargeRequest",
    "review"
  );

  const isIntake = Boolean(user?.roles?.includes(EhrRoles.INTAKE));
  const hasAccess = acceptedRoles
    ? Boolean(!isEmpty(intersection(user?.roles || [], acceptedRoles)))
    : true;

  const {
    isNavigationDrawerOpen,
    openNavigationDrawer,
    closeNavigationDrawer,
  } = useNavigationDrawer();

  const createTestDictation = (
    clinicianEmail: string,
    accessToken: string
  ): Promise<{
    appointmentId: number;
  }> =>
    api
      .post<{ appointmentId: number }>(
        `/v2/clinician/${clinicianEmail}/test_dictation`,
        {},
        accessToken
          ? {
              headers: { Authorization: accessToken },
            }
          : undefined
      )
      .then(({ data }) => data);

  return (
    <Box
      key="app-shell"
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      bgcolor={backgroundColor === "white" ? "white" : "grey.200"}
    >
      <SideEffects />

      {!hideShell ? (
        <AppBar
          className={classes.appBar}
          sx={{ backgroundColor: isClinicianPro ? "#6e76ee" : "#00a3ad" }}
        >
          <Toolbar>
            <IconButton
              disabled={!isUserAuthenticated}
              color="inherit"
              onClick={openNavigationDrawer}
              edge="start"
              className={classes.menuButton}
              size="large"
            >
              <MenuIcon />
            </IconButton>

            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              className={classes.title}
            >
              <div>{title}</div>
              {isClinicianPro ? (
                <div>
                  <Image
                    src={ProBadge as string}
                    alt="NOTO Logo"
                    width={30}
                    height={30}
                  />
                </div>
              ) : null}
            </Stack>

            {isUserAuthenticated ? (
              <UserMenu
                avatar={session?.user?.image}
                email={session?.user?.email}
              />
            ) : (
              <Button color="inherit" onClick={() => signIn("google")}>
                Log in
              </Button>
            )}
          </Toolbar>
        </AppBar>
      ) : null}

      <Drawer
        onClose={closeNavigationDrawer}
        className={classes.drawer}
        anchor="left"
        open={isNavigationDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={closeNavigationDrawer} size="large">
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <Divider />

        <List>
          <List>
            <ListItem
              button
              onClick={() => {
                closeNavigationDrawer();
                return push("/");
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
          </List>
          {isScheduler ? (
            <List
              subheader={
                <ListSubheader disableSticky>Scheduling</ListSubheader>
              }
            >
              <ListItem
                button
                onClick={() => {
                  closeNavigationDrawer();
                  return push("/clinicians");
                }}
              >
                <ListItemIcon>
                  <SupervisorAccountIcon />
                </ListItemIcon>
                <ListItemText primary="Scheduling" />
              </ListItem>
              {enableComOverviewDashboard && (
                <ListItem
                  button
                  onClick={() => {
                    closeNavigationDrawer();
                    return push("/clinicians");
                  }}
                >
                  <ListItemIcon>
                    <SportsMartialArtsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="COM Overview" />
                </ListItem>
              )}
              <ListItem
                button
                onClick={() => {
                  closeNavigationDrawer();
                  return push("/scheduling/members");
                }}
              >
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Members" />
              </ListItem>

              {isClinicianScheduleApprover ? (
                <ListItem
                  button
                  onClick={() => {
                    closeNavigationDrawer();
                    return push("/clinicians/availability-requests");
                  }}
                >
                  <ListItemIcon>
                    <ViewListIcon />
                  </ListItemIcon>
                  <ListItemText primary="Availability Requests" />
                </ListItem>
              ) : null}

              {isClinicalLeadership ? (
                <ListItem
                  button
                  onClick={() => {
                    closeNavigationDrawer();
                    return push("/debug/member-finder");
                  }}
                >
                  <ListItemIcon>
                    <PersonSearchIcon />
                  </ListItemIcon>
                  <ListItemText primary="Member Finder" />
                </ListItem>
              ) : null}

              <ListItem
                button
                onClick={() => {
                  closeNavigationDrawer();
                  return push("/transfers/requests");
                }}
              >
                <ListItemIcon>
                  <ChangeCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Transfer + Co-Therapy Requests" />
              </ListItem>

              <ListItem
                button
                onClick={() => {
                  closeNavigationDrawer();
                  return push("/member-experience");
                }}
              >
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Member Experience" />
              </ListItem>
            </List>
          ) : null}

          {isClinician ? (
            <List
              subheader={<ListSubheader disableSticky>Clinician</ListSubheader>}
            >
              {["Resigned", "Retired", "Temp Leave"].includes(
                user.current_status
              ) || successionFlag?.has_flag ? (
                <ListItem
                  button
                  onClick={() => {
                    closeNavigationDrawer();
                    return push("/succession");
                  }}
                >
                  <ListItemIcon>
                    <RepeatIcon />
                  </ListItemIcon>
                  <ListItemText primary="Plan of succession" />
                </ListItem>
              ) : null}

              <ListItem
                button
                onClick={() => {
                  closeNavigationDrawer();
                  return push("/appointments");
                }}
              >
                <ListItemIcon>
                  <WatchLaterIcon />
                </ListItemIcon>
                <ListItemText primary="My Appointments" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  closeNavigationDrawer();
                  return push("/members");
                }}
              >
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="My Members" />
              </ListItem>

              {enableCaseloadReview ? (
                <CaseloadReviewListItem
                  closeNavigationDrawer={closeNavigationDrawer}
                />
              ) : null}

              <ListItem
                button
                onClick={() => {
                  closeNavigationDrawer();
                  return push("/availability");
                }}
              >
                <ListItemIcon>
                  <EventIcon />
                </ListItemIcon>
                <ListItemText primary="My Calendar" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  closeNavigationDrawer();
                  return push("/credentials");
                }}
              >
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary="My Credentials" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  closeNavigationDrawer();
                  return push("/messaging");
                }}
              >
                <ListItemIcon>
                  <ChatIcon />
                </ListItemIcon>
                <ListItemText primary="Messaging" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  closeNavigationDrawer();
                  return push("/progress");
                }}
              >
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>

                <ListItemText primary="My Progress" />
              </ListItem>
              {enableSessionhealth && (
                <ListItem
                  button
                  onClick={() => {
                    closeNavigationDrawer();
                    return push("/session-health");
                  }}
                >
                  <ListItemIcon>
                    <MonitorHeartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Session Health" />
                </ListItem>
              )}

              <ListItem
                onClick={() => {
                  return router.push("/scheduling-tools");
                }}
              >
                <ListItemIcon>
                  <WorkHistoryOutlined />
                </ListItemIcon>
                <ListItemText primary="Scheduling Tools" />
              </ListItem>

              {isClinician ? (
                <SchedulingComplianceListItem
                  isNavigationDrawerOpen={isNavigationDrawerOpen}
                  closeNavigationDrawer={closeNavigationDrawer}
                  clinicianEmail={user.email}
                />
              ) : null}

              {enableMyEvaluations ? (
                <ListItem
                  button
                  onClick={() => {
                    closeNavigationDrawer();
                    return push("/clinician-evaluations/my");
                  }}
                >
                  <ListItemIcon>
                    <ReviewsIcon />
                  </ListItemIcon>
                  <ListItemText primary="My Evaluations" />
                </ListItem>
              ) : null}
            </List>
          ) : null}

          {isClinicianSupervisor && associateProgramEnabled ? (
            <List
              subheader={
                <ListSubheader disableSticky>Supervisor</ListSubheader>
              }
            >
              <ListItem
                button
                onClick={() => {
                  closeNavigationDrawer();
                  return push(
                    `/supervisor?tab=${encodeURIComponent(
                      "Associate Appointments"
                    )}`
                  );
                }}
              >
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Supervisor" />
              </ListItem>
            </List>
          ) : null}

          {isDiagnosticApprover ? (
            <ListItem
              button
              onClick={() => {
                closeNavigationDrawer();
                return push(`/diagnosis-approver`);
              }}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Diagnosis Approval" />
            </ListItem>
          ) : null}

          {(isClinicalLeadSpecialtyConditions ||
            isFormBaselineChangeRequestAdmin ||
            isAssessmentArchiveChangeRequestAdmin ||
            canReviewDischargeRequests ||
            canManageClinicianEvaluations) && (
            <List
              subheader={<ListSubheader disableSticky>Admin</ListSubheader>}
            >
              {canReviewDischargeRequests ? (
                <ListItem
                  button
                  onClick={() => {
                    closeNavigationDrawer();
                    return push(`/discharge-requests`);
                  }}
                >
                  <ListItemIcon>
                    <ChangeCircle />
                  </ListItemIcon>
                  <ListItemText primary="Discharge requests" />
                </ListItem>
              ) : null}

              {canManageClinicianEvaluations ? (
                <ListItem
                  button
                  onClick={() => {
                    closeNavigationDrawer();
                    return push(`/clinician-evaluations`);
                  }}
                >
                  <ListItemIcon>
                    <ReviewsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Clinician evaluations" />
                </ListItem>
              ) : null}

              {isClinicalLeadSpecialtyConditions ? (
                <ListItem
                  button
                  onClick={() => {
                    closeNavigationDrawer();
                    return push(`/clinician-specialty-conditions`);
                  }}
                >
                  <ListItemIcon>
                    <StarsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Clinician Specialty Conditions" />
                </ListItem>
              ) : null}
              {isFormBaselineChangeRequestAdmin ? (
                <ListItem
                  button
                  onClick={() => {
                    closeNavigationDrawer();
                    return push(`/form_baseline_requests_dashboard`);
                  }}
                >
                  <ListItemIcon>
                    <ChangeCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Form Baseline Requests" />
                </ListItem>
              ) : null}

              {isAssessmentArchiveChangeRequestAdmin ? (
                <ListItem
                  button
                  onClick={() => {
                    closeNavigationDrawer();
                    return push(`/form_archive_requests_dashboard`);
                  }}
                >
                  <ListItemIcon>
                    <ChangeCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Assessment Archive Requests" />
                </ListItem>
              ) : null}
            </List>
          )}

          {isMemberAdvocate || isMemberAdvocateAuditor || isIntake ? (
            <List
              subheader={<ListSubheader disableSticky>Care Team</ListSubheader>}
            >
              {isIntake ? (
                <ListItem
                  button
                  onClick={() => {
                    closeNavigationDrawer();
                    return push("/intake");
                  }}
                >
                  <ListItemIcon>
                    <HeadsetMicIcon />
                  </ListItemIcon>
                  <ListItemText primary="Intake" />
                </ListItem>
              ) : null}
              {isIntake ? (
                <ListItem
                  button
                  onClick={() => {
                    closeNavigationDrawer();
                    return push(
                      `/referrals?tab=${encodeURIComponent(
                        "Inbound Referrals"
                      )}`
                    );
                  }}
                >
                  <ListItemIcon>
                    <HeadsetMicIcon />
                  </ListItemIcon>
                  <ListItemText primary="Inbound Referrals" />
                </ListItem>
              ) : null}
              {isMemberAdvocate ? (
                <ListItem
                  button
                  onClick={() => {
                    closeNavigationDrawer();
                    return push("/member-advocate-messaging");
                  }}
                >
                  <ListItemIcon>
                    <ChatIcon />
                  </ListItemIcon>
                  <ListItemText primary="Member Advocate Messaging" />
                </ListItem>
              ) : null}
              {isMemberAdvocateAuditor ? (
                <ListItem
                  button
                  onClick={() => {
                    closeNavigationDrawer();
                    return push("/audit-member-advocate-messaging");
                  }}
                >
                  <ListItemIcon>
                    <VisibilityIcon />
                  </ListItemIcon>
                  <ListItemText primary="Audit Advocate Messaging" />
                </ListItem>
              ) : null}
            </List>
          ) : null}
          <List
            subheader={<ListSubheader disableSticky> Resources </ListSubheader>}
          >
            <ListItem
              button
              onClick={() => {
                window.open(
                  `${process.env.NEXT_PUBLIC_WEB_APP_BASE_URL}/login?redirectTo=/community`,
                  "_blank"
                );
              }}
            >
              <ListItemIcon>
                <ForumIcon />
              </ListItemIcon>
              <ListItemText primary="NOCD Community" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                window.open(
                  `https://sites.google.com/nocdhelp.com/knowledgehub/home`,
                  "_blank"
                );
              }}
            >
              <ListItemIcon>
                <ForumIcon />
              </ListItemIcon>
              <ListItemText primary="Knowledge Hub" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                window.open(
                  `https://sites.google.com/nocdhelp.com/people-hub/home`,
                  "_blank"
                );
              }}
            >
              <ListItemIcon>
                <ForumIcon />
              </ListItemIcon>
              <ListItemText primary="People Hub" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                window.open(
                  `https://www.treatmyocd.com/therapist-referral-program`,
                  "_blank"
                );
              }}
            >
              <ListItemIcon>
                <ForumIcon />
              </ListItemIcon>
              <ListItemText primary="Therapist Referral Program" />
            </ListItem>
            <ListItem
              button
              onClick={async () => {
                try {
                  const { appointmentId } = await createTestDictation(
                    user.email,
                    session?.accessToken
                  );
                  closeNavigationDrawer();
                  void push(`/appointments/${appointmentId}`);
                } catch (error) {
                  console.error(
                    "Error creating test dictation appointment:",
                    error
                  );
                }
              }}
            >
              <ListItemIcon>
                <ForumIcon />
              </ListItemIcon>
              <ListItemText primary="Try NOTO Dictation" />
            </ListItem>
          </List>
        </List>
      </Drawer>

      {!hideShell ? <Toolbar /> : null}

      {/* eslint-disable-next-line no-nested-ternary */}
      {isAuthenticating ? (
        <div className={classes.contentWrapper} key="authenticating">
          <Grid justifyContent="center" container>
            <CircularProgress color="primary" />
          </Grid>
        </div>
      ) : isUserAuthenticated ? (
        <div className={classes.contentWrapper} key="authenticated">
          {hasAccess ? children : <AccessDenied isLoading={!user} />}
        </div>
      ) : (
        <div className={classes.contentWrapper} key="not-authenticated">
          <NotAuthenticated onClickLogIn={() => signIn("google")} />
        </div>
      )}
    </Box>
  );
}
