export enum SessionTypes {
  DIAGNOSTIC_ASSESSMENT = "*OCD DIAGNOSTIC ASSESSMENT",
  THERAPY_SESSION = "Therapy Session",
  FIRST_THERAPY_SESSION = "First Therapy Session",
  MID_THERAPY_SESSION = "Mid Therapy Session",
  THERAPY_CHECK_IN = "Therapy Check In",
  LOC_THERAPY_CHECK_IN = "LOC THERAPY CHECK-IN",
  SUPPORT_CHECK_IN = "Support Check In",
  THREE_MONTH_SUPPORT_CHECK_IN = "3 Month Support Check In",
  SIX_MONTH_SUPPORT_CHECK_IN = "6 Month Support Check In",
  NINE_MONTH_SUPPORT_CHECK_IN = "9 Month Support Check In",
  TWELVE_MONTH_SUPPORT_CHECK_IN = "12 Month Support Check In",
  FORMS_PLACEHOLDER = "Forms Placeholder",
}

export enum SessionTypesV2 {
  INITIAL_ASSESSMENT = "Initial Assessment",
  FOLLOWUP_ASSESSMENT = "Followup Assessment",
  THERAPY_SESSION = "Therapy Session",
  THERAPY_SESSION_WITH_FORMS = "Therapy Session w/ Forms",
  RESTART_DIAGNOSTIC_ASSESSMENT = "Reassessment",
  FORMS_PLACEHOLDER = "Forms Placeholder",
}

export enum SessionTypesV3 {
  FIRST_SESSION = "First Session",
  SECOND_SESSION = "Second Session",
  THERAPY_SESSION = "Therapy Session",
  REASSESSMENT = "Reassessment",
  NONMEMBER_EDUCATION_SESSION = "Non-member Education Session",
  SECOND_REASSESSMENT = "Second Reassessment",
}

export enum ProtocolTypes {
  ADULT = "Adult",
  ADOLESCENT = "Adolescent",
  CHILD = "Child",
}

export enum Countries {
  AUSTRALIA = "Australia",
  UNITED_STATES = "United States",
  UNITED_KINGDOM = "United Kingdom",
  CANADA = "Canada",
  OTHER = "Other",
}

export interface MemberDocument<P = unknown, M = unknown> {
  id: number;
  document_id: number;
  user_id: number;
  payload: P;
  created_at: Date | null;
  submission_id: string;
  metadata: M;
  deleted_at: Date | null;
  document_name: string;
  document_version: number;
  document_descriptor: string;
  template_id: string;
}

export enum MemberChartPageTabs {
  MEMBER_INFO = "Member info",
  MEMBER_PORTAL = "Member portal",
  SCHEDULER = "Scheduler",
  BULK_CANCEL = "Bulk cancel",
  SEQUENCE = "Sequence",
  DIAGNOSIS_CODES = "ICD Codes",
  CONTACT_NOTE = "Contact note",
  DISCHARGE_NOTES = "Discharge notes",
  // Leaving here for backwards compatibility
  TRANSFERS = "Transfers",
  TRANSFERS_AND_CO_THERAPY = "Transfers + Co-Therapy",
  DOCUMENTS = "Documents",
  SAFETY_PLAN = "Support plan",
  SOS_EXERCISES = "SOS/Exercises",
  TIMING = "Timing",
  INFORMED_CONSENT = "Informed consent and NPP",
  RELEASE_OF_INFORMATION = "Release of information",
  NOTICE_OF_PRIVACY_PRACTICES = "Notice of Privacy Practices",
  SUBTYPES = "Subtypes",
  CARE_TEAM = "Care Team",
  CASE_NOTES = "Case Notes",
  FORMS_VIEWER = "Forms Viewer",
  JOURNAL_ENTRIES = "Journal Entries",
  PERSONAL_GOALS = "Personal Goals",
  HISTORICAL_ASSESSMENT_RESULTS = "Historical Assessment Results",
  THERAPY_MANAGEMENT = "Therapy Management",
  FEAR_AND_RESPONSE_LIST = "Fear & Response List",
  HOMEWORK = "Homework/Hierarchy",
  LEGACY_EXPOSURES = "Exposures (Legacy)",
  MESSAGING = "Messaging",
  TRAUMA_INTERVIEW = "Trauma Interview",
  MEMBER_INTERVIEW_FORMS = "Member Interview Forms",
  HLOC_NOTES = "Higher Level of Care Notes",
  REFERRALS = "Referrals",
  CARE_COORDINATION = "Care coordination/Referrals",
  FUNCTIONAL_ASSESSMENT = "Functional Assessment",
  CLINICIAN_SCRATCHPAD = "Clinician scratchpad",
  MEMBER_REMINDER = "Member Reminders",
  SESSIONS = "Sessions",
  TIMELINE = "Timeline",
  MEMBER_INSIGHTS = "Member Insights",
  MEMBER_MEDICATIONS = "Member Medications",
  MEMBER_GOALS = "Member Goals",
}
