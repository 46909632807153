import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { AppointmentInsights } from "@features/appointment-insights-drawer/components/AppointmentInsights";
import { Button } from "@mui/material";
import React, { useRef, useState } from "react";
import { useQuery, UseQueryOptions } from "react-query";

interface SessionRecapResponse {
  insights: {
    session_notes_summary: string[];
    updates_since_last_session: string[];
    assessment_summaries: string[];
    questions: string[];
  };
}

export const fetchSessionRecap = async (
  appointmentId: number,
  accessToken: string
): Promise<SessionRecapResponse> => {
  const { data } = await api.get(
    `/v1/therapy/members/session_recap/${appointmentId}`,
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return data;
};

export const useSessionRecap = (
  appointmentId: number,
  options?: UseQueryOptions<SessionRecapResponse, Error>
) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery<SessionRecapResponse, Error>(
    ["session-recap", appointmentId, accessToken],
    () => fetchSessionRecap(appointmentId, accessToken),
    {
      enabled: appointmentId != null && !!accessToken,
      staleTime: Infinity,
      ...options,
    }
  );
};

const SessionRecapModal: React.FC<{ appointment_id: number }> = ({
  appointment_id,
}) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <div style={{ width: "100%" }}>
      <Button
        ref={buttonRef}
        variant="contained"
        disableElevation
        onClick={() => setOpen(true)}
        fullWidth
        sx={{
          backgroundColor: "#EDF0FD",
          color: "#6271EB",
          textTransform: "uppercase",
          fontWeight: "500",
          fontSize: "12px",
          height: "38px",
          padding: "0 12px",
          borderRadius: "19px",
          "&:hover": {
            backgroundColor: "#DFE4FC",
          },
        }}
      >
        SESSION PREP
      </Button>
      <AppointmentInsights
        appointmentId={appointment_id}
        isOpen={open}
        onClose={() => setOpen(false)}
        drawerOffset={360}
      />
    </div>
  );
};

export default SessionRecapModal;
