import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

import { ExpiredKaiserSchedulingAlert } from "../types";

interface Response {
  alerts: ExpiredKaiserSchedulingAlert[];
}

export const useExpiredKaiserSchedulingAlerts = (): UseQueryResult<
  Response,
  Error
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    ["kaiser-expired-scheduling-alerts", accessToken],
    ({ signal }) =>
      api
        .get<Response>(`/v1/admin/kaiser_renewal/expired_scheduling_alerts`, {
          headers: {
            Authorization: accessToken,
          },
          signal,
        })
        .then(({ data }) => data)
        .catch(transformAPIError),
    {
      enabled: !!accessToken,
    }
  );
};
