import { useSession } from "@core/hooks/useSession";
import { transformAPIError } from "@core/services/nocd-api";
import { AxiosError } from "axios";
import useSWR, { SWRResponse } from "swr";
import invariant from "tiny-invariant";

import api from "./api";
import { APIError, MemberAppointment } from "./types";

export const getMemberAppointmentQueryKey = (
  appointmentId: string | number | undefined,
  accessToken: string | undefined
): string | undefined =>
  appointmentId && accessToken
    ? `member-appointment-${appointmentId}-${accessToken}`
    : undefined;

const getMemberAppointment = (
  appointmentId: number | string,
  accessToken: string | undefined
): Promise<MemberAppointment> =>
  api
    .get<MemberAppointment>(
      `/v2/clinician/member_appointments/${appointmentId}`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => {
      return data;
    })
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const useMemberAppointment = (
  appointmentId: string | number
): SWRResponse<MemberAppointment, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  const swrProps = useSWR<MemberAppointment, Error>(
    getMemberAppointmentQueryKey(appointmentId, accessToken),
    () =>
      getMemberAppointment(
        appointmentId ? +appointmentId : appointmentId,
        accessToken
      )
  );

  return swrProps;
};

export const updateMemberAppointment = (
  appointmentId: number | string,
  payload: MemberAppointment,
  accessToken: string | undefined
): Promise<MemberAppointment> =>
  api
    .post<MemberAppointment>(
      `/v2/clinician/member_appointments/${encodeURIComponent(appointmentId)}`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

interface SendAddendumPayload {
  addendum: string;
}

export const addAddendumToMemberAppointment = (
  appointmentId: number,
  payload: SendAddendumPayload,
  accessToken: string | undefined
): Promise<MemberAppointment> =>
  api
    .post<MemberAppointment>(
      `/v2/clinician/member_appointments/${encodeURIComponent(
        appointmentId
      )}/addendums`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

interface SignMemberAppointmentPayload {
  summary_private_notes: string;
  sign_note_session_length: string;
  needs_supervisor_review: number;
  is_initial_assessments_created: number;
  supervisor_review_status: string | null;
  who_is_scheduling_next_session: string | null;
  scheduling_team_additional_notes: string | null;
  scheduling_team_sessions_quantity: number | null;
  scheduling_team_sessions_unit: string | null;
  scheduling_team_duration: number | null;
  diagnosis_approval_status: string | null;
}

export const signMemberAppointment = (
  appointmentId: number,
  payload: SignMemberAppointmentPayload,
  accessToken: string | undefined
): Promise<MemberAppointment> =>
  api
    .post<MemberAppointment>(
      `v2/clinician/member_appointments/${encodeURIComponent(
        appointmentId
      )}/sign`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export type GetICDCodesResponse = {
  icd_codes: {
    name: string;
    is_hidden: boolean;
    is_provisional: boolean;
    mapped_code: string | null;
    code: string;
    description: string;
  }[];
  past_icd_codes: string[];
  past_primary_icd_code: string;
  past_provisional_icd_code: string[];
};

export const getICDCodes = (
  accessToken: string | undefined,
  memberId: string | number
): Promise<GetICDCodesResponse> =>
  api
    .get<GetICDCodesResponse>(
      `/v4/clinician/icd_codes/${memberId}`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

interface SignMemberAppointmentAsAssociatePayload {
  summary_private_notes: string;
  sign_note_session_length: string | undefined | null;
  requested_supervisor: string | undefined;
  needs_supervisor_review: number;
  supervisor_review_status: string;
  is_initial_assessments_created: number;
}

export const submitMemberAppointmentAsAssociate = (
  appointmentId: number,
  payload: SignMemberAppointmentAsAssociatePayload,
  accessToken: string | undefined
): Promise<MemberAppointment> =>
  api
    .post<MemberAppointment>(
      `v2/clinician/member_appointments/${encodeURIComponent(
        appointmentId
      )}/submit_note_as_associate`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

interface SignMemberAppointmentForDiagnosisReviewPayload {
  summary_private_notes: string;
  sign_note_session_length: string | undefined | null;
  is_initial_assessments_created: number;
}

export const submitMemberAppointmentForDiagnosisReview = (
  appointmentId: number,
  payload: SignMemberAppointmentForDiagnosisReviewPayload,
  accessToken: string | undefined
): Promise<MemberAppointment> =>
  api
    .post<MemberAppointment>(
      `v2/clinician/member_appointments/${encodeURIComponent(
        appointmentId
      )}/submit_note_for_diagnosis_approval`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

interface AddReviewNotePayload {
  note_text: string;
  member_appointment_id: number;
}

export const addReviewNoteToMemberAppointment = (
  payload: AddReviewNotePayload,
  accessToken: string | undefined
): Promise<MemberAppointment> => {
  invariant(
    payload.member_appointment_id != null,
    "member_appointment_id is required"
  );
  return api
    .post<MemberAppointment>(
      `/v2/clinician/member_appointments/${encodeURIComponent(
        payload.member_appointment_id
      )}/review_note`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

interface AddDiamondAssessemntPayload {
  appointment_id: number;
  protocol_type: string;
}

export const addDiamondAssessmentToAppointmentNote = (
  payload: AddDiamondAssessemntPayload,
  accessToken: string | undefined
): Promise<MemberAppointment> => {
  invariant(payload.appointment_id != null, "appointment_id is required");
  return api
    .post<MemberAppointment>(
      `/v2/clinician/member_appointments/${encodeURIComponent(
        payload.appointment_id
      )}/diamond_assessment`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

interface VerifyParentalAgreementPayload {
  user_id: number;
  appointment_id: number;
  clinician_email: string;
}

export const verifyParentalAgreement = (
  payload: VerifyParentalAgreementPayload,
  accessToken: string | undefined
): Promise<MemberAppointment> => {
  invariant(
    payload.user_id != null || payload.appointment_id != null,
    "user_id is required"
  );
  return api
    .post<MemberAppointment>(
      `/v2/clinician/member_appointments/${encodeURIComponent(
        payload.appointment_id
      )}/verify_parental_agreement`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};
