import { useMember, useMemberAppointment } from "@core/services/nocd-api";
import { useSessionRecap } from "@features/member/SessionRecap";
import {
  CalendarTodayOutlined,
  InventoryOutlined,
  RestartAlt,
} from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Box, keyframes, Skeleton, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { makeStyles } from "@mui/styles";
import { format, utcToZonedTime } from "date-fns-tz";
import { isNil } from "lodash";
import * as React from "react";

import { AppointmentInsightsProps } from "../types";
import { InsightCard } from "./InsightCard";
import { Questions } from "./Questions";

const useStyles = makeStyles({
  drawer: {
    width: 425,
    maxWidth: "100%",
    paddingTop: "4rem", // Adjust this value as needed to move the drawer content out from under the task bar
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2rem",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
  headerContent: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  },
  icon: {
    width: 32,
    height: 32,
    backgroundColor: "#3f51b5",
    borderRadius: "50%",
    color: "#fff",
  },
  titleGroup: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    textTransform: "uppercase",
    fontSize: "1rem",
    color: "#3F51B5",
  },
  name: {
    fontSize: "1.5rem",
    color: "rgba(0, 0, 0, 0.9)",
  },
  content: {
    padding: "1rem",
    overflowY: "auto",
    maxHeight: "calc(100vh - 150px)",
  },
  closeButton: {
    cursor: "pointer",
  },
});

const waveAnimation = keyframes`
  0% { background-position: 0% 50%; }
  25% { background-position: 50% 0%; }
  50% { background-position: 100% 50%; }
  75% { background-position: 50% 100%; }
  100% { background-position: 0% 50%; }
`;

const borderAnimation = keyframes`
  0% {
    border-image-source: linear-gradient(135deg, 
      rgba(205, 175, 243, 0.8) -2.61%,
      rgba(140, 198, 252, 0.8) 50.85%,
      rgba(0, 163, 173, 0.8) 132.94%
    );
  }
  50% {
    border-image-source: linear-gradient(225deg,
      rgba(205, 175, 243, 0.8) -2.61%,
      rgba(140, 198, 252, 0.8) 50.85%,
      rgba(0, 163, 173, 0.8) 132.94%
    );
  }
  100% {
    border-image-source: linear-gradient(135deg,
      rgba(205, 175, 243, 0.8) -2.61%,
      rgba(140, 198, 252, 0.8) 50.85%,
      rgba(0, 163, 173, 0.8) 132.94%
    );
  }
`;

// Component
export const AppointmentInsights: React.FC<AppointmentInsightsProps> = ({
  appointmentId,
  isOpen,
  onClose,
  drawerOffset,
}) => {
  const classes = useStyles();
  const { data: appointmentData } = useMemberAppointment(appointmentId);
  const { data: member } = useMember(
    !isNil(appointmentData?.user_id) ? appointmentData?.user_id : undefined
  );
  const { data: sessionRecap, isLoading: isSessionRecapLoading } =
    useSessionRecap(Number(appointmentId));
  const { insights } = sessionRecap ?? {};
  const hasInsights = insights?.session_notes_summary?.length > 0;

  const formatAppointmentDate = () => {
    const timeZone = appointmentData?.clinician_timezone ?? "America/Chicago";
    const value = appointmentData?.start_time_in_iso8601_utc;

    if (value) {
      try {
        const appointmentInClinicianTime = utcToZonedTime(
          new Date(value),
          timeZone
        );

        return format(
          appointmentInClinicianTime,
          "EEE, MMM d, yyyy h:mm aaa zzz",
          {
            timeZone,
          }
        );
      } catch (error) {
        console.error("Error formatting appointment date:", error);
        return "Invalid Date";
      }
    }

    return undefined; // Returns undefined when value is null or missing
  };

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="right"
      hideBackdrop={false}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      }}
      sx={{
        "& .MuiDrawer-paper": {
          width: `${hasInsights ? 600 : 400}px`,
          margin: "16px",
          marginRight: `${drawerOffset || 16}px`,
          height: "calc(100% - 125px)",
          borderRadius: "30px",
          background: `
            linear-gradient(135deg, 
              rgba(205, 175, 243, 0.6) -2.61%, 
              rgba(140, 198, 252, 0.3) 50.85%, 
              rgba(0, 163, 173, 0.3) 132.94%
            )
          `,
          backgroundColor: "#FFFFFF",
          backgroundSize: "200% 200%",
          animation: `${waveAnimation} 8s cubic-bezier(0.3, 0, 0.3, 1) infinite`,
          boxShadow: `
            0px 4px 20px rgba(0, 0, 0, 0.4),
            0 0 15px rgba(205, 175, 243, 0.5),
            0 0 30px rgba(140, 198, 252, 0.3)
          `,
          overflow: "auto",
          overflowX: "hidden",
          overflowY: "hidden",
          position: "fixed",
          right: 0,
          top: "66px",
          bottom: "50px",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: "30px",
            padding: "2.5px",
            background: `linear-gradient(135deg, 
              rgba(205, 175, 243, 0.8) -2.61%,
              rgba(140, 198, 252, 0.8) 50.85%,
              rgba(0, 163, 173, 0.8) 132.94%
            )`,
            mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
            maskComposite: "exclude",
            WebkitMask:
              "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
            WebkitMaskComposite: "xor",
            animation: `${borderAnimation} 8s cubic-bezier(0.3, 0, 0.3, 1) infinite`,
            pointerEvents: "none",
          },
          "&::after": {
            content: '""',
            position: "absolute",
            top: -1,
            left: -1,
            right: -1,
            bottom: -1,
            borderRadius: "30px",
            background: "transparent",
            filter: "blur(8px)",
            opacity: 0.7,
            animation: `${borderAnimation} 8s cubic-bezier(0.3, 0, 0.3, 1) infinite`,
            pointerEvents: "none",
          },
        },
      }}
    >
      <Box className={classes.header}>
        <Box className={classes.headerContent}>
          <AccountCircleIcon className={classes.icon} />
          <Box className={classes.titleGroup}>
            <Box display="flex" alignItems="center" gap="0.5rem">
              <InventoryOutlined
                style={{ color: "#3F51B5", width: 18, height: 18 }}
              />
              <Typography className={classes.title}>Session Prep</Typography>
            </Box>

            {member ? (
              <Typography className={classes.name}>
                {member?.first_name} {member?.last_name}
              </Typography>
            ) : (
              <Skeleton width="100%" height={40} />
            )}

            <Box display="flex" alignItems="center" gap="0.5rem">
              <CalendarTodayOutlined style={{ width: 16, height: 16 }} />
              {appointmentData ? (
                <Typography variant="body2" color="textSecondary">
                  {formatAppointmentDate()}
                </Typography>
              ) : (
                <Skeleton width="100%" height={20} />
              )}
            </Box>
          </Box>
        </Box>
        <CloseIcon className={classes.closeButton} onClick={onClose} />
      </Box>

      <Box className={classes.content}>
        <InsightCard
          icon={<RestartAlt />}
          title="Last session recap"
          items={insights?.session_notes_summary}
          noItemsMessage="No updates since last session"
          isLoading={isSessionRecapLoading}
        />
        <InsightCard
          icon={<TrendingUpIcon />}
          title="Updates since last session"
          items={insights?.updates_since_last_session}
          noItemsMessage="No updates since last session"
          isLoading={isSessionRecapLoading}
        />
        <InsightCard
          icon={<ReceiptIcon />}
          title="Assessments"
          items={insights?.assessment_summaries}
          noItemsMessage="No updates since last session"
          isLoading={isSessionRecapLoading}
        />
        <Questions
          questions={insights?.questions}
          isLoading={isSessionRecapLoading}
        />
      </Box>
    </Drawer>
  );
};
